import React from 'react';
import CenteredPopup from './PopUp';
import ploomberLogo from "./logo.svg";
import "./login.css";

const PLATFORM_URL = process.env.REACT_APP_PLATFORM_URL || 'https://www.platform.ploomber.io';

const Login = ({ isOpen, setIsOpen }) => {
  const handleConnect = () => {
    window.open(`${PLATFORM_URL}/signin?utm_medium=convert`, '_blank');
    setIsOpen(false);
  };

  const handleSignUp = () => {
    window.open(`${PLATFORM_URL}/register?utm_medium=convert`, '_blank');
    setIsOpen(false);
  }

  return (
    <CenteredPopup isOpen={isOpen} onClose={() => setIsOpen(false)} title="">
      <img className="logo" src={ploomberLogo} alt="Ploomber logo" />
      <div className="login-container">
        <p className="login-message">
          You must be signed in to your Ploomber account to use this service.
        </p>
        <div className="button-signin" onClick={handleConnect}>
          Login
        </div>
        <p className="register-redirect">
          Need an account?<br />
          <div className="link" onClick={handleSignUp}>Sign up for free</div>
        </p>
      </div>
    </CenteredPopup>
  );
};

export default Login;
