import React from "react";

const Header = ({ isWideLayout }) => (
    <div className="bg-gray-100 border-b border-b-black">
        <div className={`${isWideLayout ? 'max-w-[98rem]' : 'max-w-4xl'} mx-auto bg-white shadow-md flex flex-col`}>
            <header className="border-x-black border-x px-4">
                <div className="px-0 md:px-4 py-4 flex justify-between items-center">
                    <a href="/" className="text-black font-medium">
                        convert.ploomber.io
                    </a>
                    <div className="flex items-center gap-6">
                        <a href="https://www.platform.ploomber.io/applications?utm_medium=convert" className="text-black underline">
                            <span className="hidden sm:inline">Ploomber Cloud</span>
                            <span className="sm:hidden">Cloud</span>
                        </a>
                        <a href="https://editor.ploomber.io/?utm_medium=convert" className="text-black underline">
                            <span className="hidden sm:inline">Streamlit AI Editor</span>
                            <span className="sm:hidden">Editor</span>
                        </a>
                        <img className="w-8 h-8 mr-4" src="/images/icon.png" alt="Ploomber icon" />
                    </div>
                </div>
            </header>
        </div>
    </div>
)

export default Header;
