import React from "react";

const Title = () => {
    return (
        <h1 className="text-5xl font-bold text-center mb-8">
            <span className="relative inline-block">
                <span className="absolute inset-0 bg-[#FC7557] transform rotate-[-2deg] z-10"></span>
                <span className="relative text-white px-2 z-20">Advanced</span>
            </span>
            <span className="text-gray-700 ml-2">Jupyter Notebook to PDF converter</span>
        </h1>
    );
};

export default Title;
