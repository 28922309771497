import React, { useEffect } from "react";
import NotebookConverter from "./components/NotebookConverter";
import posthog from 'posthog-js';
import { BrowserRouter, Route, Routes, useRouteError } from "react-router-dom";
import './index.css';
import NotFound from "./components/NotFound";
import { AuthProvider } from "./components/login/AuthProvider";
import AdvancedNotebookConverter from "./components/NoteBookConverterAdvanced";

export const CONVERTERS_MAP = {
  "pdf": { route: "nbconvert-pdf", displayName: "PDF", label: "PDF" },
  "latex": { route: "nbconvert-latex", displayName: "LaTeX", label: "LaTeX" },
  "html": { route: "nbconvert-html", displayName: "HTML", label: "HTML for web viewing" },
  "py": { route: "nbconvert-py", displayName: "Python", label: "Python script" },
  "rst": { route: "nbconvert-rst", displayName: "reStructuredText", label: "reStructuredText for documentation" }
};

export const CONVERTERS = Object.keys(CONVERTERS_MAP)


function ErrorBoundary() {
  const error = useRouteError();
  return (
    <p className="ErrorMessage"> Error: {error.message} </p>
  );
}


function App() {
  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'dev') {
      posthog.init(process.env.REACT_APP_PH_API_KEY, { api_host: 'https://app.posthog.com', autocapture: true });
    }
  }, []);

  let dispatchPHEvent = (event, properties = {}) => {
    if (process.env.REACT_APP_ENV === 'dev') {
      return
    }

    let distinctId = posthog.persistence.props.distinct_id;
    let url = `https://app.posthog.com/capture`;

    let request = {
      "api_key": process.env.REACT_APP_PH_API_KEY,
      "event": event,
      "properties": {
        "distinct_id": distinctId,
        ...properties
      },
    }

    fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(request)
    })
      .then(response => response.json())
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <AuthProvider>
      <div className="App">
        <BrowserRouter>
          <main>
            <Routes>
              <Route
                path="/"
                element={<NotebookConverter dispatchPHEvent={dispatchPHEvent} converter={CONVERTERS[0]} />}
                errorElement={<ErrorBoundary />}
              />
              {CONVERTERS.map(format => (
                <Route
                  path={`/${format}`}
                  element={<NotebookConverter dispatchPHEvent={dispatchPHEvent} converter={format} />}
                  errorElement={<ErrorBoundary />}
                />
              ))}
              <Route path="/pdf-advanced" element={<AdvancedNotebookConverter />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
        </BrowserRouter>
      </div>
    </AuthProvider>
  );
}

export default App;
