import React, { useState, useEffect } from 'react';
import Login from './Login';


const API_URL = process.env.REACT_APP_PLATFORM_API_URL || 'https://cloud-prod.ploomber.io';
const IS_DEV = process.env.REACT_APP_ENV === 'dev';

// Fetches the current user's data from the API.
const getUser = async () => {
  try {
    const response = await fetch(`${API_URL}/users/me`, {
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error("User not connected");
    }
    const data = await response.json();
    if (data) {
      return data;
    }
  } catch (e) {
    console.error('Error fetching user data:', e);
  }
  return null;
};

// Context to store the authentication state
const AuthContext = React.createContext({
  isAuthenticated: false,
  user: null,
  setUser: () => { },
  checkIsAuthenticated: async () => false,
});

// Provider component to wrap your app
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const checkIsAuthenticated = async () => {
    setIsLoading(true);
    const userData = await getUser();
    setUser(userData);
    setIsLoading(false);
    return userData !== null;
  }

  // Initial authentication check
  useEffect(() => {
    checkIsAuthenticated();
  }, []);

  return (
    <AuthContext.Provider value={{
      isAuthenticated: !!user,
      isLoading,
      user,
      setUser,
      checkIsAuthenticated
    }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook to use auth context
export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};


/**
 * Higher-order component for guarding actions that require authentication.
 * 
 * @param {React.ComponentType} WrappedComponent - The component to be wrapped with authentication guard.
 * @returns {React.ComponentType} A new component that includes authentication check.
 * 
 * @example
 * const ProtectedButton = withAuthGuard(({ onClick, children, className }) => (
 *   <div className={className} onClick={onClick}>{children}</div>
 * ));
 */
export const withAuthGuard = (WrappedComponent) => {
  return function WithAuthGuard(props) {
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const { checkIsAuthenticated } = useAuth();

    const guardedProps = Object.keys(props).reduce((acc, key) => {
      if (typeof props[key] === 'function') {
        acc[key] = async (...args) => {
          try {
            const isAuthenticated = await checkIsAuthenticated();
            // do not require authentication in dev mode
            if (isAuthenticated || IS_DEV) {
              return props[key](...args);
            } else {
              setIsLoginOpen(true);
            }
          } catch (error) {
            console.error('Authentication check failed:', error);
            setIsLoginOpen(true);
          }
        };
      } else {
        acc[key] = props[key];
      }
      return acc;
    }, {});

    return (
      <>
        <WrappedComponent {...guardedProps} />
        <Login isOpen={isLoginOpen} setIsOpen={setIsLoginOpen} />
      </>
    );
  };
};
