import React from 'react';

const CenteredPopup = ({ isOpen, onClose, children, title }) => {
  if (!isOpen) return null;

  return (
    <div className="centered-popup-overlay">
      <div 
        className="centered-popup-backdrop"
        onClick={onClose}
      ></div>
      <div className="centered-popup-content">
        <div className="centered-popup-header">
          <h2 className="centered-popup-title">{title}</h2>
        </div>
        <div className="centered-popup-body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default CenteredPopup;
