import React from 'react';

function Footer() {
    return (
        <div className="text-center text-sm italic mt-4 fixed bottom-0 left-0 right-0 bg-white py-2">
            Processing time can be affected by input file size and internet connection speed.
        </div>
    )
}

export default Footer;
