import React from "react";

const Title = ({ converter = "pdf" }) => {
    return (
        <h1 className="text-5xl font-bold text-center py-8">
            <span className="text-gray-700">Convert Jupyter Notebooks </span>
            <br />
            <span className="text-gray-700 ml-2">to </span>
            <span className="relative inline-block">
                <span className="absolute inset-0 bg-[#979DFF] transform rotate-1 z-10"></span>
                <span className="relative text-white px-2 z-20"> {converter.toUpperCase()}</span>
            </span>
            <span className="text-gray-700 ml-2"> online</span>
            <br />
        </h1>
    );
};

export default Title;

