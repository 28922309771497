import React, { useEffect, useState } from "react";

const TopBanner = () => {
    const bannerOptions = [
        {
            text: "Deploy Streamlit apps for free on ",
            link: "Ploomber Cloud!",
            url: "https://platform.ploomber.io/register/?utm_medium=convert&utm_source=streamlit"
        },
        {
            text: "Deploy Shiny apps for free on ",
            link: "Ploomber Cloud!",
            url: "https://platform.ploomber.io/register/?utm_medium=convert&utm_source=shiny-r"
        },
        {
            text: "Deploy Dash apps for free on ",
            link: "Ploomber Cloud!",
            url: "https://platform.ploomber.io/register/?utm_medium=convert&utm_source=dash"
        },
        {
            text: "Try our new Streamlit ",
            link: "AI Editor!",
            url: "https://editor.ploomber.io/?utm_medium=convert&utm_source=editor"
        }
    ];

    const [bannerText, setBannerText] = useState(null);
    const [isChanging, setIsChanging] = useState(false);


    useEffect(() => {
        const updateBanner = () => {
            setIsChanging(true);

            setTimeout(() => {
                let newOption;
                do {
                    newOption = bannerOptions[Math.floor(Math.random() * bannerOptions.length)];
                } while (bannerText && newOption.text + newOption.link === bannerText.text + bannerText.link);

                setBannerText(newOption);
                setIsChanging(false);
            }, 500);
        };

        // Initial banner set without transition
        setBannerText(bannerOptions[0]);

        // Start transitions after 5 seconds
        const timeout = setTimeout(() => {
            updateBanner();
            const interval = setInterval(updateBanner, 5000);
            return () => clearInterval(interval);
        }, 5000);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className="sticky top-0 text-center bg-[#E6B94B] py-2 z-50 border-black border-b">
            <p className={`transition-opacity duration-500 ease-in-out ${isChanging ? 'opacity-0' : 'opacity-100'}`}>
                {bannerText && (
                    <>
                        {bannerText.text}
                        <a
                            href={bannerText.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-extrabold text-black"
                        >
                            {bannerText.link}
                        </a>
                    </>
                )}
            </p>
        </div>
    );
}

export default TopBanner;
