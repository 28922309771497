import React, { useEffect, useState } from "react";

export const apiArgs = [
    { id: "hide-code", title: "Hide code", defaultValue: "false" },
    {
        id: "converter", title: "Converter", defaultValue: "nbconvert-webpdf", options: [
            { value: "nbconvert-webpdf", label: "WebPDF (recommended if you have plotly charts)" },
            { value: "nbconvert-pdf", label: "PDF with LaTeX support (recommended if you have equations)" },
        ]
    }
]

function PdfOptions({ selectedArgs, setSelectedArgs, queryParamChanged }) {

    const [expandPDFOptions, setExpandPDFOptions] = useState(true);

    useEffect(() => {
        const defaultSelectedArgs = {};
        const urlParams = new URLSearchParams(window.location.search);

        apiArgs.forEach(a => {
            const paramValue = urlParams.get(a.id);
            if (paramValue && a.options && a.options.some(option => option.value === paramValue)) {
                defaultSelectedArgs[a.id] = paramValue;
            } else if (a.id === "hide-code") {
                defaultSelectedArgs[a.id] = paramValue === "true" ? "true" : "false";
            } else {
                defaultSelectedArgs[a.id] = a.defaultValue;
            }
        });

        setSelectedArgs(defaultSelectedArgs);
    }, []);

    return (
        <div className={`w-full p-4 border rounded-lg shadow-sm ${expandPDFOptions ? 'max-h-96' : 'max-h-12'} overflow-hidden`}>
            <div className="flex items-center justify-between cursor-pointer h-4" onClick={() => setExpandPDFOptions(!expandPDFOptions)}>
                <p className="font-medium flex-1">PDF options</p>
                <span className="text-lg font-bold">
                    {expandPDFOptions ? '▲' : '▼'}
                </span>
            </div>

            {expandPDFOptions && (
                <div className="mt-4 space-y-4">
                    {apiArgs.map(a => {
                        if (a.id === "hide-code") {
                            return (
                                <div key={a.id} className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        checked={selectedArgs[a.id] === 'true'}
                                        onChange={(e) => queryParamChanged(a.id, e)}
                                        className="w-4 h-4 rounded focus:ring-2 focus:ring-offset-2"
                                    />
                                    <span>{a.title}</span>
                                </div>
                            );
                        } else if (a.id === "converter") {
                            return (
                                <div key={a.id} className="flex flex-col space-y-2">
                                    <span>{a.title}</span>
                                    <select
                                        value={selectedArgs[a.id]}
                                        onChange={(e) => queryParamChanged(a.id, e)}
                                        className="border rounded-md py-1.5 px-3 focus:outline-none focus:ring-2 focus:ring-opacity-50"
                                    >
                                        {a.options.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            )}
        </div>
    );
}

export default PdfOptions;
