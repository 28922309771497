import React, { useState, useEffect } from 'react';
import ProtectedButton from '../../login/ProtectedAction';

const DownloadTimerButton = ({ downloadReadyData, onDownload, className }) => {
    const [timeLeft, setTimeLeft] = useState(0);
    const [isExpired, setIsExpired] = useState(false);

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = Math.floor(Date.now() / 1000);
            return Math.max(0, downloadReadyData.validUntil - now);
        };

        // Initial calculation
        setTimeLeft(calculateTimeLeft());
        setIsExpired(calculateTimeLeft() <= 0);

        // Set up interval
        const timer = setInterval(() => {
            const remaining = calculateTimeLeft();
            setTimeLeft(remaining);

            if (remaining <= 0) {
                setIsExpired(true);
                clearInterval(timer);
            }
        }, 1000);

        // Cleanup
        return () => clearInterval(timer);
    }, [downloadReadyData.validUntil]);

    if (isExpired) {
        return null;
    }

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}m ${remainingSeconds}s`;
    };

    return (
        <ProtectedButton
            onClick={onDownload}
            className={`${className} ${isExpired ? "Disabled" : ""}`}
            disabled={isExpired}
        >
            Download - Link valid for {formatTime(timeLeft)}
        </ProtectedButton>
    );
};

export default DownloadTimerButton;
