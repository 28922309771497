import React from "react";
import { AdsCloud, AdsEditor } from "./Ads";
import Header from "./Header";
import TopBanner from "./TopBanner";

const Layout = ({ children, layoutStyle = 'default' }) => {
    const isWideLayout = layoutStyle === 'wide';

    return (
        <div className="flex flex-col min-h-screen">
            <TopBanner />
            <Header isWideLayout={isWideLayout} />
            {/* Main Container */}
            <div className="flex-1 bg-gray-100 flex flex-col">
                <div className={`mx-auto bg-white border-black border-x w-full shadow-md flex-1 flex flex-col ${isWideLayout ? 'max-w-[98rem]' : 'max-w-4xl'}`}>
                    {/* Main Content Area */}
                    <main className="p-4 md:p-12 flex-1 flex flex-col">
                        <div className="flex-1 space-y-4">
                            <div className="text-gray-600 flex flex-col min-w-0 overflow-hidden">
                                {children}
                                <div className="py-4" />
                                {isWideLayout ? (
                                    <div className="flex flex-col xl:flex-row space-x-0 xl:space-x-4 space-y-9 xl:space-y-0 sm:gap-1 mt-auto">
                                        <div className="w-full flex-shrink-1"><AdsCloud isWideLayout={true} /></div>
                                        <div className="w-full flex-shrink-1"><AdsEditor /></div>
                                    </div>
                                ) : (
                                    <div className="mt-auto flex-shrink-1 min-w-0">
                                        <AdsCloud />
                                        <div className="mt-24" />
                                        <AdsEditor />
                                    </div>
                                )}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default Layout;
