import React, { useState, useEffect } from 'react';
import marketing from '../../marketing.json';

const CustomButton = ({ link, text }) => (
    <div>
        <div className="relative inline-block">
            {/* Shadow/Background layer */}
            <div className="absolute -bottom-2 -right-2 bg-transparent border-2 border-black w-full h-full"></div>
            {/* Main button */}
            <button className="relative bg-black text-white px-8 py-3 font-semibold hover:-translate-y-1 hover:-translate-x-1 transition-transform" onClick={() => window.open(link, '_blank')}>
                {text}
            </button>
        </div>
    </div>
);

export const AdsCloud = ({ isWideLayout }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            setIsTransitioning(true);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % marketing.length);
                setIsTransitioning(false);
            }, 1000); // Wait for fade out before changing content
        }, 10000); // Change every 10 seconds
        return () => clearInterval(timer);
    }, []);

    return (
        <div className="flex flex-col sm:flex-row min-w-[320px] sm:min-w-[620px] mx-auto p-6 sm:p-8 sm:h-72 items-center bg-[#7F9DFF] rounded-lg relative">
            {/* Content */}
            <div className="w-full sm:flex-1 text-white z-10 sm:h-56 flex flex-col mb-6 sm:mb-0">
                <div
                    className={`transition-opacity duration-1000 ${isTransitioning ? 'opacity-0' : 'opacity-100'
                        }`}
                >
                    <h2 className="text-4xl font-bold mb-3 sm:max-w-[320px] break-words">
                        {marketing[currentIndex].name}
                    </h2>
                    <p className="text-md opacity-90 mb-6 sm:max-w-[320px]">
                        {marketing[currentIndex].description}
                    </p>
                </div>
                <div>
                    <CustomButton
                        text="Get Started On Ploomber Cloud"
                        link={`https://platform.ploomber.io/register/${marketing[currentIndex].url_args}`} />
                </div>
            </div>

            {/* Image content */}
            <div className={`w-full sm:w-[40%] bg-white border-2 border-gray-400 rounded-lg
                shadow-lg p-4 sm:p-8 sm:absolute sm:bottom-8 sm:right-8 flex items-center justify-center overflow-hidden
                sm:h-56  ${isWideLayout ? "h-56 w-[40%]" : "md:h-72 w-[50%]"}  
                `}>
                <div className={`transition-all duration-1000 w-full h-full flex items-center
                  justify-center ${isTransitioning ? 'opacity-0 scale-95' : 'opacity-100 scale-100'
                    }`}>
                    <img
                        src={"/images/marketing/" + marketing[currentIndex].img}
                        alt={marketing[currentIndex].name}
                        className="w-full h-full object-contain"
                    />
                </div>
            </div>
        </div>
    );
};

export const AdsEditor = () => {
    return (
        <div className="flex flex-col sm:flex-row min-w-[320px] sm:min-w-[620px] mx-auto p-6 sm:h-72 items-center bg-[#FC7557] rounded-lg relative">
            <div className={`w-full sm:w-[40%] bg-white border-2 border-gray-400 rounded-lg shadow-lg p-4 sm:p-8
                sm:absolute sm:bottom-8 sm:left-8 flex items-center justify-center overflow-hidden
                h-56 sm:h-56 mb-6 sm:mb-0`}>
                <div className="transition-all duration-1000 w-full h-full flex items-center justify-center">
                    <img
                        src="/images/marketing/editor.png"
                        alt="Streamlit AI Editor"
                        className="w-full h-full object-contain"
                    />
                </div>
            </div>

            <div className="text-white z-10 flex flex-col w-full sm:w-1/2 sm:ml-auto sm:pr-8">
                <div className="transition-opacity duration-1000">
                    <h2 className="text-3xl sm:text-4xl font-bold mb-3 sm:max-w-[350px] break-words">
                        Streamlit AI Editor
                    </h2>
                    <p className="text-sm sm:text-md opacity-90 mb-6 sm:max-w-[350px]">
                        Go from Idea to Web Application, all in Python from your Browser
                    </p>
                </div>
                <CustomButton text="Try our Streamlit AI Editor" link="https://editor.ploomber.io/?utm_medium=convert&utm_source=bottom_banner" />
            </div>
        </div>
    );
};
