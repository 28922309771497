import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CONVERTERS, CONVERTERS_MAP } from '../../App';
import Layout from '../templates/Layout';
import DownloadTimerButton from './components/DownloadTimerButton';
import StyledDropzone from './components/Dropzone';
import PdfOptions from './components/PdfOptions';
import Title from './components/Title';

const getFileExtension = (contentType) => {
    contentType = contentType.replace("text/", "");
    console.log(contentType);
    if (contentType.includes('pdf')) {
        return 'pdf';
    } else if (contentType.includes('tex')) {
        return 'tex';
    } else if (contentType.includes('html')) {
        return 'html';
    } else if (contentType.includes('python')) {
        return 'py';
    } else if (contentType.includes('rst')) {
        return 'rst';
    } else {
        console.warn(`Unknown content type: ${contentType}`);
        return 'txt';
    }
};

// add typescript to project and use enum
const STATES = {
    START: "start",
    UPLOADING: "uploading"
}

const PH_EVENTS = {
    FILE_SELECTED: "notebook-file-selected",
    CONVERT_STARTED: "notebook-convert-started",
    CONVERT_SUCCESS: "notebook-converted",
    CONVERT_FAILED: "failed-to-convert-notebook",
    CONVERT_DOWNLOAD: "notebook-download",
}

function NotebookConverter({ dispatchPHEvent, converter }) {

    const navigate = useNavigate();
    const [state, setState] = useState(STATES.START);
    const [disabled, setDisabled] = useState(true);
    const [file, setFile] = useState(null);
    const [downloadReadyData, setDownloadReadyData] = useState(null);
    const [selectedArgs, setSelectedArgs] = useState({ "converter": converter, "hide-code": "false" });
    const errorMessage = useRef(null);

    useEffect(() => {
        setDisabled(!file);
    }, [file])

    useEffect(() => {
        try {
            const fileReadyToDownload = JSON.parse(localStorage.getItem("downloadReadyData"));
            console.log(fileReadyToDownload);
            if (fileReadyToDownload && fileReadyToDownload.validUntil > Math.floor(Date.now() / 1000)) {
                setDownloadReadyData(fileReadyToDownload);
            }
        } catch (err) {
            console.error(err);
        }
    }, [])

    useEffect(() => {
        if (state === STATES.START) {
            setDisabled(!file);
        }
        else if (state === STATES.UPLOADING) {
            setDisabled(true);
        }
    }, [state])

    let convertNotebook = async () => {
        if (disabled) {
            return;
        }
        dispatchNotebookEvent();
        errorMessage.current.innerText = "";
        setState(STATES.UPLOADING);
        const formData = new FormData();
        formData.append("file", file, file.name);

        let url = `${process.env.REACT_APP_API_URL}/notebooks/upload?converter-type=${selectedArgs['converter']}&hide-code=${selectedArgs['hide-code']}`;

        try {
            // Initial upload
            const uploadResponse = await fetch(url, {
                method: "POST",
                body: formData
            });

            if (uploadResponse.status !== 202) {
                throw new Error(`Upload failed with status ${uploadResponse.status}`);
            }

            const uploadResult = await uploadResponse.json();
            const jobId = uploadResult.job_id;

            // Poll for job completion
            while (true) {
                const statusResponse = await fetch(`${process.env.REACT_APP_API_URL}/notebooks/result/${jobId}`);

                if (statusResponse.status === 200) {
                    const result = await statusResponse.json();
                    const uuid = result.result;
                    // Notebook link is valid for 500sec, but let's a sens of create urgency
                    const fizeMinutes = 60 * 5
                    const fileReady = {
                        uuid,
                        validUntil: Math.floor(Date.now() / 1000) + fizeMinutes,
                        notebookName: file.name
                    };
                    setDownloadReadyData(fileReady)
                    localStorage.setItem("downloadReadyData", JSON.stringify(fileReady));
                    dispatchConvertionSuccessEvent();
                    break;
                } else if (statusResponse.status === 202) {
                    // Job still processing, wait before checking again
                    await new Promise(resolve => setTimeout(resolve, 1000));
                } else {
                    throw new Error(`Job failed with status ${statusResponse.status}`);
                }
            }
        } catch (err) {
            let errMessage = `Error: ${err.message}`;
            errorMessage.current.innerText = errMessage;
            dispatchConvertionFailedEvent(err);
        } finally {
            setState(STATES.START);
        }
    };

    let handleDownloadNotebook = async () => {
        if (downloadReadyData) {
            if (downloadReadyData.validUntil < Math.floor(Date.now() / 1000)) {
                return
            }
            // Download result
            const downloadResponse = await fetch(`${process.env.REACT_APP_API_URL}/download/${downloadReadyData.uuid}/`);

            if (downloadResponse.status !== 200) {
                throw new Error(`Download failed with status ${downloadResponse.status}`);
            }
            const blob = await downloadResponse.blob();
            const contentType = downloadResponse.headers.get("content-type");

            // Create download link
            const url = URL.createObjectURL(blob);
            let fileName = downloadReadyData.notebookName.split('.').slice(0, -1).join('.');
            const extension = getFileExtension(contentType);
            const a = document.createElement('a');
            a.setAttribute('download', `${fileName}.${extension}`);
            a.setAttribute('href', url);
            a.click();
            dispatchDownloadSuccessEvent();
        }
    };

    let queryParamChanged = (argId, e) => {
        let value = e.target.type === 'checkbox' ? (e.target.checked ? 'true' : 'false') : e.target.value;
        let updatedArgs = { ...selectedArgs, [argId]: value };
        setSelectedArgs(updatedArgs);

        // Update URL
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(argId, value);
        const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);
    }

    let handleConverterChange = (event) => {
        const type = event.target.value.replace("nbconvert-", "");
        navigate(`/${type}`);
        queryParamChanged("converter", event);

    }

    // events
    let dispatchFileSelectedEvent = (error) => {
        let event = PH_EVENTS.FILE_SELECTED;
        dispatchPHEvent(event);
    }

    let dispatchNotebookEvent = () => {
        let properties = {};
        Object.keys(selectedArgs).map(key => {
            properties[key] = selectedArgs[key];
        })

        if (file) {
            properties["file_name"] = file.name
        }

        let event = PH_EVENTS.CONVERT_STARTED;
        dispatchPHEvent(event, properties);
    }


    let dispatchConvertionSuccessEvent = (error) => {
        let properties = {};
        Object.keys(selectedArgs).map(key => {
            properties[key] = selectedArgs[key];
        })

        if (file) {
            properties["file_name"] = file.name
        }

        let event = PH_EVENTS.CONVERT_SUCCESS;
        dispatchPHEvent(event, properties);
    }

    let dispatchDownloadSuccessEvent = () => {
        let properties = {};

        if (downloadReadyData && downloadReadyData.notebookName) {
            properties["file_name"] = downloadReadyData.notebookName
        }

        let event = PH_EVENTS.CONVERT_DOWNLOAD;
        dispatchPHEvent(event, properties);
    }

    let dispatchConvertionFailedEvent = (error) => {
        let properties = {
            "error_stack": error.stack,
            "error_message": error.message
        };

        if (file) {
            properties["file_name"] = file.name
        }

        let event = PH_EVENTS.CONVERT_FAILED;
        dispatchPHEvent(event, properties);
    }

    return (
        <Layout>
            <Title converter={converter} />
            <div className="mb-12 mt-8">
                <div data-testid='converter' className="max-w-2xl mx-auto">
                    <div className="flex flex-col items-center space-y-6">
                        <div className="w-full">
                            <StyledDropzone
                                onDrop={files => {
                                    let file = files[0];
                                    setFile(file);
                                    dispatchFileSelectedEvent();
                                }}
                                onDropRejected={() => {
                                    alert("File type is not allowed. You can only upload .ipynb files")
                                }}
                            />

                            <div className="mt-4 text-gray-700">
                                Selected file: {file ? <span className="font-medium">{file.name}</span> : 'No file'}
                            </div>

                            <div key="converter-selector" className="mt-4 flex items-center gap-2">
                                <span>Convert Notebook to:</span>
                                <select
                                    value={selectedArgs["converter"]}
                                    onChange={handleConverterChange}
                                    className="border rounded-md py-1.5 px-3 focus:outline-none focus:ring-2 focus:ring-opacity-50"
                                >
                                    {CONVERTERS.map(converter => (
                                        <option key={CONVERTERS_MAP[converter].route} value={CONVERTERS_MAP[converter].route}>
                                            {CONVERTERS_MAP[converter].label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {selectedArgs["converter"].includes("pdf") &&
                            <PdfOptions selectedArgs={selectedArgs} setSelectedArgs={setSelectedArgs} queryParamChanged={queryParamChanged} />
                        }
                    </div>

                    <div className="flex flex-col items-center mt-8 space-y-4">
                        <div className="text-center">
                            <button
                                className={`Button transition-all duration-200 mb-4 ${disabled ? "opacity-50 cursor-not-allowed" : "hover:shadow-lg"}`}
                                onClick={convertNotebook}
                                disabled={disabled}
                            >
                                <span>
                                    {state === STATES.UPLOADING
                                        ? "Converting..."
                                        : `Convert to ${CONVERTERS_MAP[converter].displayName}`}
                                </span>
                            </button>
                            <div className="text-red-500 mt-2" ref={errorMessage}></div>
                            {selectedArgs["converter"].includes("pdf") &&
                                <span className="text-sm text-gray-600">
                                    Looking for more customization? Check out the <a href="/pdf-advanced" className="underline">Advanced PDF converter</a>.
                                </span>
                            }
                        </div>

                        {downloadReadyData && (
                            <DownloadTimerButton
                                downloadReadyData={downloadReadyData}
                                onDownload={handleDownloadNotebook}
                                className="Button hover:shadow-lg transition-all duration-200"
                            />
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default NotebookConverter;

