import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CONVERTERS } from '../App';


function NotFound() {
  const navigate = useNavigate();

  return (
    <div className='NotFoundPage'>
      <h1 className='Title'>
        Convert your Notebook to:
      </h1>
      <div className="WrapContainer">

        {CONVERTERS.map(format => (
          <button
            key={format}
            onClick={() => navigate(`/${format.toLowerCase()}`)}
            className="ButtonSecondary"
          >
            {format}
          </button>
        ))}

      </div>
    </div>
  );
}

export default NotFound;
